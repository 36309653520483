import times from "lodash-es/times";
import React from "react";

interface IControllerComponentProps {
  count: number;
  current: number;
  next(): void;
  prev(): void;
  goTo(index: number): void;
}
export default function Controller({ count, current, next, prev, goTo }: IControllerComponentProps) {
  return (
    <div className="fullpage-controller" style={{ display: "none" }}>
      <p className="controller navigation up" onClick={prev} />
      {times(count, c => (
        <p key={c} className={`controller${current === c ? " current" : ""}`} onClick={() => goTo(c)} />
      ))}
      <p className="controller navigation down" onClick={next} />
    </div>
  );
}
