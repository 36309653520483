import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { CSSProperties, useMemo } from "react";

interface IProps {
  centered?: boolean;
  className?: string;
  fillContainer?: boolean;
}

export default function Loading({ centered, className = "", fillContainer }: IProps) {
  const styles = useMemo(() => {
    const css: CSSProperties = {
      alignItems: "center",
      display: "flex",
      fontSize: 24,
      justifyContent: "center"
    };
    if (centered) {
      css.height = "100vh";
      css.width = "100vw";
    }
    if (fillContainer) {
      css.height = "100%";
      css.width = "100%";
    }
    return css;
  }, [centered, fillContainer]);

  return (
    <div className={`${className} ${centered ? "centered" : ""}`} style={styles}>
      <FontAwesomeIcon icon={["fas", "spinner"]} pulse={true} />
    </div>
  );
}
