import { Dispatch } from "redux";
import { IActivity } from "../../interfaces/IActivity";
import { ICar } from "../../interfaces/ICar";
import { IFlight } from "../../interfaces/IFlight";
import { IRoom } from "../../interfaces/IHotel";
import { IOtherService } from "../../interfaces/IOtherService";
import { api } from "../../services/api";
import { GetState } from "../../types";
import {
  BASKET_CHANGE_CURRENCY,
  BASKET_CHANGE_PAYMENT_METHOD,
  BASKET_CLEAR,
  BASKET_FETCH_EMPTY,
  BASKET_FETCH_FULFILLED,
  BASKET_FETCH_PENDING,
  BASKET_FETCH_REJECTED,
  BASKET_RESET_FULFILLED,
  BASKET_RESET_PENDING,
  BASKET_RESET_REJECTED
} from "../../utils/constants";
import { getCustomersGuids, getCustomersValidForBasket } from "../selectors/customerSelectors";
import { closeHotelDetailScreen, toggleBasket } from "./uiActions";

export type ActionTypes = "flight" | "hotel" | "car" | "visa" | "viptransfer" | "transfer" | "activity";
export type ItemTypes = IFlight | IRoom | ICar | IOtherService | IActivity;
export type Batch = Array<{ item: ItemTypes; action: ActionTypes }>;

interface IRequestPayload {
  action_type: ActionTypes;
  basket_guid: string;
  custguid: Array<{ custguid: string }>;
  offer_guid: string;
}

export const addBatchToBasket = (batch: Batch) => (dispatch: Dispatch) =>
  Promise.all(batch.map(payload => dispatch<any>(addItemToBasket(payload.item, payload.action))));

export const addItemToBasket = (item: ItemTypes, action: ActionTypes) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  const state = getState();
  const { basketguid } = state.basket.basket;
  const custguid = getCustomersGuids(state)!;

  return api()
    .post("basket", {
      action_type: action,
      basket_guid: basketguid,
      custguid,
      offer_guid: (item as any).OfferId || (item as any).offer_guid,
      [action]: item
    } as IRequestPayload)
    .then(() => {
      dispatch<any>(toggleBasket(true));
      dispatch<any>(closeHotelDetailScreen());
    })
    .catch(() => null);
};

export const resetBasket = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  dispatch({
    type: BASKET_RESET_PENDING
  });
  return api()
    .put("basket", { basket_guid: state.basket.basket.basketguid, status: "PENDING" })
    .then(() => {
      dispatch({
        type: BASKET_RESET_FULFILLED
      });
      return Promise.resolve();
    })
    .catch(() => {
      dispatch({
        type: BASKET_RESET_REJECTED
      });
      return Promise.reject();
    });
};

export const fetchBasket = (newBasket = false) => async (dispatch: Dispatch, getState: GetState) => {
  const { basketguid, currency } = getState().basket.basket;

  dispatch({
    type: BASKET_FETCH_PENDING
  });

  const params: any = { currency };

  if (newBasket) {
    dispatch({
      type: BASKET_CLEAR
    });
  } else {
    params.basket_guid = basketguid;
  }
  try {
    const { data } = await api().get("basket", { params });
    if (data.basket) {
      dispatch({
        payload: data.basket,
        type: BASKET_FETCH_FULFILLED
      });
    } else {
      dispatch({
        type: BASKET_FETCH_EMPTY
      });
    }
  } catch {
    dispatch({
      type: BASKET_FETCH_REJECTED
    });
  }
};

export const deleteItem = (OfferId: string) => (dispatch: Dispatch, getState: GetState) => {
  const { basketguid } = getState().basket.basket;
  return api()
    .delete("basket", {
      data: {
        basket_guid: basketguid,
        offer_guid: OfferId
      }
    })
    .then(() => {
      dispatch<any>(fetchBasket());
    })
    .catch(() => null);
};

export const changeCurrency = (currency: string) => (dispatch: Dispatch) => {
  dispatch({
    payload: currency,
    type: BASKET_CHANGE_CURRENCY
  });
  dispatch<any>(fetchBasket());
};

export const changePaymentMethod = (checked: boolean) => {
  return {
    payload: checked ? "cash" : "credit",
    type: BASKET_CHANGE_PAYMENT_METHOD
  };
};

export const resetAndAddtoBasket = (item: ItemTypes, action: ActionTypes) => async (
  dispatch: Dispatch<any>,
  getState: GetState
) => {
  const customersValidForBasket = getCustomersValidForBasket(getState());
  if (customersValidForBasket) {
    return dispatch(addItemToBasket(item, action));
  } else {
    return (dispatch(resetBasket()) as any).then(() => dispatch(addItemToBasket(item, action)));
  }
};
