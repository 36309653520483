import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import tooltip from "react-tooltip";
import Notifications from "../../containers/Notifications";
import findRoute from "../../routes/routeMap";
import { toggleBasket } from "../../store/actions/uiActions";
import { getUserMenu } from "../../store/selectors/userSelectors";
import HeaderUserPanel from "../snippets/HeaderUserPanel";

const HeaderMenu: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const menu = useSelector(getUserMenu);

  useEffect(() => {
    tooltip.rebuild();
  }, [menu]);

  const basketHandler = useCallback(() => {
    dispatch(toggleBasket());
  }, [dispatch]);

  return (
    <div className="menu">
      <div className="links">
        {menu.map(main =>
          main.subitems ? (
            <div key={main.code} className="has-dropdown">
              <FontAwesomeIcon icon={main.iconClass!} />
              <ul className="dropdown-links hidden">
                {main.subitems.map(sub => (
                  <li key={sub.code}>
                    <NavLink to={findRoute(sub.code)}>{sub.title}</NavLink>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <NavLink key={main.code} data-tip={main.title} to={findRoute(main.code)}>
              <FontAwesomeIcon icon={main.iconClass!} />
            </NavLink>
          )
        )}
        <button data-tip={t("header.link.basket")} className="external-service" onClick={basketHandler}>
          <FontAwesomeIcon icon={["far", "shopping-cart"]} />
        </button>
        <Notifications />
        <HeaderUserPanel />
      </div>
    </div>
  );
};

export default HeaderMenu;
