import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import startCase from "lodash-es/startCase";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions/userActions";
import { getUser } from "../../store/selectors/userSelectors";
import ThemeChanger from "../ThemeChanger";

interface IProps {
  mobile?: boolean;
}

const HeaderUserPanel: FC<IProps> = ({ mobile = false }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  if (!mobile) {
    return (
      <div className="has-dropdown">
        <FontAwesomeIcon icon={["far", "user"]} />
        <div className="user-dropdown">
          <div className="flex j-between">
            <p>{startCase(user && user.firstname) + " " + startCase(user && user.lastname)}</p>
            <ThemeChanger />
          </div>

          <button className="logout pointer c-primary" onClick={() => dispatch(logout())}>
            Logout
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="user">
        <p>
          <span className="margin-right">
            <FontAwesomeIcon icon={["far", "user"]} />
          </span>
          <span>{startCase(user && user.firstname) + " " + startCase(user && user.lastname)}</span>
        </p>
        <button className="logout pointer c-primary" onClick={() => dispatch(logout())}>
          <FontAwesomeIcon icon={["far", "sign-out"]} />
        </button>
        <ThemeChanger />
      </div>
    );
  }
};
export default HeaderUserPanel;
