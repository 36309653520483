import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import merge from "lodash-es/merge";
import set from "lodash-es/set";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import useObjectState from "../../hooks/useObjectState";
import { IAccountingPayment } from "../../interfaces/IAccounting";
import Modal from "../../libs/Modal";
import { api } from "../../services/api";
import { defaultInvoiceRequest } from "../../utils/defaults";
import Loading from "../Loading";
import { Busy } from "../snippets/Busy";
import AccountingPriceList from "./AccountingPriceList";

interface IProps {
  items: IAccountingPayment[];
  onClose(): void;
}

const AccountInvoice: FC<IProps> = ({ items, onClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [invoiceRequest, setInvoiceRequest] = useObjectState(
    merge(defaultInvoiceRequest, {
      invoice: {
        invoiceData: {
          currency: items[0].currency,
          invoiceItems: items,
          totalAmount: items.reduce((total, i) => total + i.total_amount, 0),
          totalVat: items.reduce((total, i) => total + i.vat_amount, 0),
        },
      },
      items,
    })
  );
  const [response, setResponse] = useState<any>({});
  const [isBusy, setIsBusy] = useState(false);

  const inputHandler = useCallback(
    (field: string, value: any) => {
      setInvoiceRequest(set({ ...invoiceRequest }, field, value));
    },
    [invoiceRequest, setInvoiceRequest]
  );

  const submit = useCallback(async () => {
    setIsBusy(true);
    try {
      const { data } = await api().post("paymentinvoice", invoiceRequest);
      setResponse(data);
    } catch {}
    setIsBusy(false);
  }, [invoiceRequest]);

  const fetchInvoiceInfo = async () => {
    setLoading(true);
    try {
      const { data } = await api().get("invoiceinfo", {
        params: { key: items[0].customer },
      });
      setInvoiceRequest({
        invoice: {
          invoiceData: {
            invoice: data,
          },
        },
      });
    } catch {}
    setLoading(false);
  };

  useEffect(() => {
    fetchInvoiceInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal.Container onClose={onClose}>
      <Modal.Header>
        <h1>Invoice Actions</h1>
      </Modal.Header>
      <Modal.Body>
        {isBusy && <Busy />}
        {loading ? (
          <Loading />
        ) : (
          !!invoiceRequest.invoice.invoiceData.invoice && (
            <table className="table is-fullwidth invoice-table fs-std">
              <tbody>
                <tr>
                  <td className="cell-align-bottom">
                    <table>
                      <tbody>
                        <tr>
                          <td colSpan={2}>{invoiceRequest.invoice.invoiceData.invoice.title}</td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            {invoiceRequest.invoice.invoiceData.invoice.address_line1} <br />
                            {invoiceRequest.invoice.invoiceData.invoice.address_line2}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {t("accounting.invoice.taxOffice")}:
                            <br />
                            {invoiceRequest.invoice.invoiceData.invoice.vkzone}
                          </td>
                          <td>
                            {t("accounting.invoice.taxNo")}:
                            <br />
                            {invoiceRequest.invoice.invoiceData.invoice.vkno}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {t("accounting.invoice.type")}:
                            <br />
                            <select
                              value={invoiceRequest.invoice.invoiceType}
                              onChange={(e) => inputHandler("invoice.invoiceType", e.target.value)}
                            >
                              <option value="">Select</option>
                              <option value="EFATURA">EFATURA</option>
                              <option value="FATURA">FATURA</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {t("accounting.invoice.reference")}:
                            <br />
                            <input
                              type="text"
                              placeholder="invoice reference"
                              value={invoiceRequest.invoice.invoiceReference}
                              onChange={(e) => inputHandler("invoice.invoiceReference", e.target.value)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {t("accounting.invoice.date")}:
                            <br />
                            <NumberFormat
                              value={invoiceRequest.invoice.invoiceDate}
                              onValueChange={({ value }) => inputHandler("invoice.invoiceDate", value)}
                              format="####.##.##"
                              placeholder="YYYY.MM.DD"
                              mask={["Y", "Y", "Y", "Y", "M", "M", "D", "D"]}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          )
        )}
        <AccountingPriceList items={items} />
      </Modal.Body>
      <Modal.Footer>
        {response.status && (
          <>
            <p>{t("accounting.invoiceSubmit")}</p>
            <p>
              {t("accounting.invoiceKey")}: {response.invoice.invoiceKey}
            </p>
          </>
        )}
        <button disabled={response && response.status} className="submit modal-right-button" onClick={submit}>
          <FontAwesomeIcon icon={["far", "check-circle"]} />
        </button>
      </Modal.Footer>
    </Modal.Container>
  );
};

export default AccountInvoice;
