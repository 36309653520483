import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import useClickDetect from "../../hooks/useClickDetect";

interface IProps {
  label?: string;
  options?: string[];
  value?: string;

  onChange(value: string): void;
}

const Select: FC<IProps> = ({ label, options, value, onChange }) => {
  const { t } = useTranslation();

  const $el = useClickDetect(() => setIsActive(false));
  const [isActive, setIsActive] = useState(false);

  const selectHandler = (option: string) => {
    onChange(option);
    setIsActive(false);
  };

  return (
    <div ref={$el} className="custom-select">
      {label && <h6 className="fs-small">{label}</h6>}
      <p onClick={() => setIsActive(true)}>
        {value ? `${t("generic.room")} ${value}` : t("customer.selectRoom")}
      </p>
      {isActive && (
        <div className="custom-select-options">
          {options!.map((o) => (
            <p onClick={() => selectHandler(o)} key={o}>
              {t("generic.room")} {o}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
