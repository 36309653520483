import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { FC, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { AgencyLogo } from "../components/AgencyLogo";
import ExchangeRates from "../components/ExchangeRates";
import HeaderMenu from "../components/header/HeaderMenu";
import HeaderMenuMobile from "../components/header/HeaderMenuMobile";
import Search from "../components/search/Search";
import { useWindowSize } from "../hooks/useWindowSize";
import Basket from "./Basket";
import CompanyChanger from "./CompanyChanger";

interface IProps {
  hasSearch?: boolean;
}

const Header: FC<IProps> = ({ hasSearch = true }) => {
  const { width } = useWindowSize();
  const [isOpen, setIsOpen] = useState(false);

  const isMobile = useMemo(() => {
    return width < 900;
  }, [width]);

  const toggleMenu = () => {
    setIsOpen(x => !x);
  };

  const cxRightSide = classNames("right-side", {
    "is-mobile": isMobile,
    "is-open": isOpen
  });

  return (
    <div className="index-fixer container">
      <div id="header" className="header index-fixer">
        <div className="main-area index-fixer">
          <div className="band">
            <div className="brand">
              <NavLink to="/">
                <AgencyLogo />
              </NavLink>
            </div>
            <div className="title">
              <CompanyChanger />
            </div>
            {isMobile && (
              <div className="mobile-menu-toggle" onClick={toggleMenu}>
                <FontAwesomeIcon icon={["far", "bars"]} />
              </div>
            )}
          </div>
          {hasSearch && <Search />}
          {hasSearch && <Basket />}
        </div>
        <div className={cxRightSide}>
          <ExchangeRates />
          {isMobile ? <HeaderMenuMobile /> : <HeaderMenu />}
        </div>
      </div>
    </div>
  );
};

export default Header;
