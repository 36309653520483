import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import isUndefined from "lodash-es/isUndefined";
import React, { CSSProperties, FC, useEffect, useMemo, useState } from "react";
import { useTranslation, withTranslation, WithTranslation } from "react-i18next";
import { batch, useDispatch } from "react-redux";
import tooltip from "react-tooltip";
import { ICheckoutData } from "../interfaces/IBooking";
import { IHotel, IRoom } from "../interfaces/IHotel";
import { IBookingStatus } from "../interfaces/IState";
import Thumbnail from "../libs/Image/Thumbnail";
import HotelDetailsScreenButton from "../modules/hotelDetailsScreen/components/HotelDetailsScreenButton";
import { resetAndAddtoBasket } from "../store/actions/basketActions";
import {
  setBasketData,
  setBasketIsPending,
  setCheckoutIsPending,
  setCheckoutScreenData,
  toggleCustomerScreen
} from "../store/actions/uiActions";
import { ReduxDispatch } from "../types";
import { pwc, stripHtmlTags } from "../utils/helpers";
import ButtonWithConfirm from "./confirmation/ButtonWithConfirm";
import Dates from "./Dates";
import Gallery from "./Gallery";
import Rooms from "./Rooms";
import AmenityList from "./snippets/AmenityList";
import { Busy } from "./snippets/Busy";
import Rating from "./snippets/Rating";
import { SourceFooter } from "./SourceFooter";

interface IProps extends WithTranslation {
  bookingStatus: IBookingStatus;
  customersValidForBasket: boolean;
  customersValidForBooking: boolean;
  hotel: IHotel;
  style?: CSSProperties;

  makeSearch(): void;
  selectRoom(room: IRoom): void;
}

const Hotel: FC<IProps> = ({ hotel, style = {}, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<ReduxDispatch>();
  const [galleryIsActive, setGalleryIsActive] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  const selectedRoom = useMemo(() => {
    const room = hotel.rooms.find(r => r.selected!) || hotel.rooms[0];
    if (room) {
      return {
        ...room,
        action: "hotel"
      };
    }
  }, [hotel]);

  const hasImages = useMemo(() => {
    return !isUndefined(hotel.images) && hotel.images.length > 0;
  }, [hotel.images]);

  const selectedRoomPrice = useMemo(() => {
    if (selectedRoom) {
      return pwc(selectedRoom.AmountAfterTax, selectedRoom.HotelCurrency, false);
    }
    return pwc(parseInt(hotel.MinRate!), hotel.HotelCurrency, false);
  }, [selectedRoom, hotel.MinRate, hotel.HotelCurrency]);

  const toggleGallery = () => {
    setGalleryIsActive(!galleryIsActive);
  };

  useEffect(() => {
    tooltip.rebuild();
  }, []);

  const checkoutData: ICheckoutData | undefined = useMemo(() => {
    if (selectedRoom) {
      return {
        actionType: "hotel",
        amount: selectedRoom!.AmountAfterTax,
        currency: selectedRoom!.HotelCurrency,
        guid: selectedRoom!.OfferId,
        isInternational: hotel.isInternational,
        items: { hotels: [selectedRoom!] }
      };
    }
  }, [selectedRoom, hotel]);

  const cx = classNames("hotel-container flex", {
    agree: hotel.source === "AGREEMENT"
  });

  const dates = [
    {
      date: hotel.CheckInDate,
      direction: "0"
    },
    {
      date: hotel.CheckOutDate,
      direction: "1"
    }
  ];

  return (
    <div className={cx} style={style}>
      {galleryIsActive && hasImages && <Gallery images={hotel.images!} onClose={toggleGallery} />}
      {isBusy && <Busy />}
      <div className="hotel box is-relative">
        {hotel.source === "AGREEMENT" && (
          <div className="promotion" data-tip="Agreement">
            <FontAwesomeIcon flip="horizontal" color="#F7941D" icon={["fas", "tag"]} />
          </div>
        )}
        <div className="card flex column-gutter">
          <Thumbnail
            onClick={toggleGallery}
            width={75}
            height={75}
            rounded
            gap={10}
            layout="background"
            image={hotel.images || hotel.ThumbnailImage}
          />
          <div className="info fill-space">
            <p className="name fs-prc">{hotel.HotelName}</p>
            <span className="flex gutter-bottom-3">
              <Rating rating={hotel.Rating} />
              {!isUndefined(hotel.centerDistance) && (
                <a
                  href={hotel.centerMapLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="fs-std c-black distance"
                  data-tip="Distance to the downtown"
                >
                  <FontAwesomeIcon icon={["fas", "crosshairs"]} />
                  {hotel.centerDistance.toLocaleString("tr", {
                    maximumFractionDigits: 1
                  })}{" "}
                  km
                </a>
              )}
              {!isUndefined(hotel.airportDistance) && (
                <a
                  href={hotel.airportMapLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="fs-std c-black distance"
                  data-tip="Distance to the airport"
                >
                  <FontAwesomeIcon icon={["fas", "plane-departure"]} />
                  {hotel.airportDistance.toLocaleString("tr", {
                    maximumFractionDigits: 1
                  })}{" "}
                  km
                </a>
              )}
            </span>
            <p className="address fs-std">
              {hotel.HotelDescLong && stripHtmlTags(hotel.HotelDescLong).slice(0, 80) + "..."}
            </p>
            <SourceFooter source={hotel.source} />
          </div>
          <Dates dates={dates} />
        </div>
        <AmenityList amenities={hotel.JSONamenity} />
        <div className="rooms">
          {!!hotel.rooms.length && <Rooms selectRoom={props.selectRoom} rooms={hotel.rooms} />}
        </div>
      </div>
      {checkoutData && (
        <div className="controllers">
          <p className="price-tag fs-prc is-centered">{selectedRoomPrice}</p>
          <ButtonWithConfirm
            actions={[
              {
                byPassAction: true,
                color: "success",
                handler() {
                  if (selectedRoom) {
                    setIsBusy(true);
                    dispatch(resetAndAddtoBasket(selectedRoom, "hotel"))
                      .then(() => setIsBusy(false))
                      .catch(() => setIsBusy(false));
                  }
                },
                text: "Proceed"
              },
              {
                color: "success",
                handler() {
                  batch(() => {
                    dispatch(setBasketData(selectedRoom!, "hotel"));
                    dispatch(toggleCustomerScreen());
                    dispatch(setBasketIsPending(true));
                  });
                },
                text: "Check Customers"
              }
            ]}
            className="button cart"
            message={t("customer.notValidBooking")}
            byPass={props.customersValidForBooking}
          >
            <FontAwesomeIcon icon={["fas", "shopping-cart"]} />
          </ButtonWithConfirm>
          <ButtonWithConfirm
            actions={[
              {
                byPassAction: true,
                color: "success",
                handler() {
                  dispatch(setCheckoutScreenData(checkoutData, true));
                },
                text: "Proceed"
              },
              {
                color: "success",
                handler() {
                  batch(() => {
                    dispatch(setCheckoutScreenData(checkoutData));
                    dispatch(toggleCustomerScreen());
                    dispatch(setCheckoutIsPending(true));
                  });
                },
                text: "Check Customers"
              }
            ]}
            className="button buy"
            message={t("customer.notValidBooking")}
            byPass={props.customersValidForBooking}
          >
            <FontAwesomeIcon icon={["fas", "credit-card-front"]} />
          </ButtonWithConfirm>
          <HotelDetailsScreenButton hotel={hotel} />
        </div>
      )}
    </div>
  );
};

export default withTranslation()(Hotel);
