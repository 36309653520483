import React, { FC } from "react";
import { useSelector } from "react-redux";
import { getUserRole } from "./../store/selectors/userSelectors";

type Props = {
  source: string;
};

export const SourceFooter: FC<Props> = ({ source }) => {
  const userRole = useSelector(getUserRole);

  if (userRole === "ADMIN") {
    return <p className="fs-note c-gray source-note">{source}</p>;
  }

  return null;
};
